import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Container from "../components/container/container";
import BlockContent from "../components/block-content";
import DatasourcesListSimple from "../components/datasources-list/datasource-list-simple";
import * as styles from "./datasources.module.scss";

enum availableSections {
  page = "page",
  erp = "erp",
  erpPartners = "erpPartners",
  dc = "dc",
  cip = "cip",
  cin = "cin",
  other = "other"
}
const passwordMasterSections = [
  availableSections.page,
  availableSections.erp,
  availableSections.erpPartners,
  availableSections.dc,
  availableSections.cip,
  availableSections.cin,
  availableSections.other
];
const passwordDebtCollectionSections = [
  availableSections.page,
  availableSections.erp,
  availableSections.erpPartners,
  availableSections.cip,
  availableSections.cin,
  availableSections.other
];
const passwordCustomersSections = [
  availableSections.page,
  availableSections.erp,
  availableSections.erpPartners,
  availableSections.dc,
  availableSections.cip,
  availableSections.cin,
  availableSections.other
];

export const query = graphql`
  query DatasourcesQuery2 {
    datasourcePage: sanityDatasourcesPage {
      title
      passwordMaster
      passwordDebtCollection
      passwordCustomers
      _rawPageDescription
      _rawErpDescription
      _rawErpPartnersDescription
      _rawDcDescription
      _rawCipDescription
      _rawCinDescription
      _rawOtherDescription
    }
    datasources: allSanityDatasourceSimple {
      nodes {
        _rawDescription
        datasourceType
        name
        visible
        logo {
          alt
          asset {
            _id
          }
        }
      }
    }
  }
`;

const DatasourcesPage = props => {
  const { data, errors } = props;

  // CONSTS
  // Datasource Page
  const title = data.datasourcePage.title;

  const pageDescription = data.datasourcePage._rawPageDescription;
  const erpDescription = data.datasourcePage._rawErpDescription;
  const erpPartnersDescription = data.datasourcePage._rawErpPartnersDescription;
  const dcDescription = data.datasourcePage._rawDcDescription;
  const cipDescription = data.datasourcePage._rawCipDescription;
  const cinDescription = data.datasourcePage._rawCinDescription;
  const otherDescription = data.datasourcePage._rawOtherDescription;

  // Password
  const passwordMaster = data.datasourcePage.passwordMaster;
  const passwordDebtCollection = data.datasourcePage.passwordDebtCollection;
  const passwordCustomers = data.datasourcePage.passwordCustomers;
  const pagePasswords = {
    passwordMaster,
    passwordDebtCollection,
    passwordCustomers
  };

  // Datasources
  const datasources = data && data.datasources.nodes;

  // FUNCTIONALITY
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  // Password & security
  const [showPage, setShowPage] = useState(false);
  function handleShowPage() {
    setShowPage(true);
  }
  function handleHidePage() {
    setShowPage(false);
  }

  const [storedPassword, setPassword] = useState("");

  const handleChange = event => {
    setPassword(event.target.value);
  };

  // Section visability
  const [visibleSections, setVisibleSections] = React.useState<string[]>([]);
  const handleClick = () => {
    const matchingIndex = Object.values(pagePasswords).indexOf(storedPassword);

    if (matchingIndex !== -1) {
      const profile = Object.keys(pagePasswords)[matchingIndex];
      switch (profile) {
        case "passwordMaster":
          setVisibleSections(passwordMasterSections);
          break;
        case "passwordDebtCollection":
          setVisibleSections(passwordDebtCollectionSections);
          break;
        case "passwordCustomers":
          setVisibleSections(passwordCustomersSections);
          break;
        default:
          setVisibleSections([]);
      }
      handleShowPage();
    }
  };

  if (showPage) {
    return (
      <Layout>
        {<SEO title={title} />}
        <Container role="main">
          <div className={styles.datasourcesFullPage}>
            {visibleSections?.map(value => {
              if (value === "page") {
                return (
                  <div>
                    <BlockContent blocks={pageDescription} />
                  </div>
                );
              }
              if (value === "erp" && erpDescription) {
                return (
                  <div>
                    <BlockContent blocks={erpDescription} />
                    <DatasourcesListSimple
                      datasources={datasources.filter(
                        datasource => datasource.datasourceType === "erp"
                      )}
                    ></DatasourcesListSimple>
                  </div>
                );
              }
              if (value === "erpPartners" && erpPartnersDescription) {
                return (
                  <div>
                    <BlockContent blocks={erpPartnersDescription} />
                    <DatasourcesListSimple
                      datasources={datasources.filter(
                        datasource => datasource.datasourceType === "erpProxy"
                      )}
                    ></DatasourcesListSimple>
                  </div>
                );
              }
              if (value === "dc" && dcDescription) {
                return (
                  <div>
                    <BlockContent blocks={dcDescription} />
                    <DatasourcesListSimple
                      datasources={datasources.filter(
                        datasource => datasource.datasourceType === "debtCollection"
                      )}
                    ></DatasourcesListSimple>
                  </div>
                );
              }
              if (value === "cip" && cipDescription) {
                return (
                  <div>
                    <BlockContent blocks={cipDescription} />
                    <DatasourcesListSimple
                      datasources={datasources.filter(
                        datasource => datasource.datasourceType === "creditInformationProvider"
                      )}
                    ></DatasourcesListSimple>
                  </div>
                );
              }
              if (value === "cin" && cinDescription) {
                return (
                  <div>
                    <BlockContent blocks={cinDescription} />
                    <DatasourcesListSimple
                      datasources={datasources.filter(
                        datasource => datasource.datasourceType === "creditInsuranceProvider"
                      )}
                    ></DatasourcesListSimple>
                  </div>
                );
              }
              if (value === "other" && otherDescription) {
                return (
                  <div>
                    <BlockContent blocks={otherDescription} />
                    <DatasourcesListSimple
                      datasources={datasources.filter(
                        datasource => datasource.datasourceType === "other"
                      )}
                    ></DatasourcesListSimple>
                  </div>
                );
              }
            })}
          </div>
        </Container>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Container role="main">
          <div>
            <input
              type="text"
              id="message"
              name="message"
              onChange={handleChange}
              value={storedPassword}
            />

            <button onClick={handleClick}>Enter</button>
          </div>
        </Container>
      </Layout>
    );
  }
};

export default DatasourcesPage;
