import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BlockContent from "../block-content";
import Image from "../image/image";
import * as styles from "./datasource-list.module.scss";

const DatasourcesListSimple: React.FC<any> = ({ datasources }) => {
  return (
    <div>
      {datasources.map((ds, i) => (
        <Accordion key={`ds-${i}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel1a-content-${i}`}
            id={`panel1a-header-${i}`}
          >
            <div className={styles.datasourcesAccordionSummary}>
              {ds.logo && ds.logo.asset && (
                <Image asset={ds.logo.asset} alt={ds.logo.alt} fit={"crop"}></Image>
              )}
              <Typography className={styles.datasourcesDsName}>{ds.name}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {ds._rawDescription && <BlockContent blocks={ds._rawDescription || []} />}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
export default DatasourcesListSimple;
